import React from "react";
import "./App.css";
import _ from "lodash";
import { HealthStatusSlice, generateColor, HealthStatus } from "./util";
import Parameters from "./Parameters";

interface IStatusOverTimeProps {
  statusesOverTime: HealthStatusSlice[];
}

export default class StatusOverTime extends React.Component<
  IStatusOverTimeProps,
  {}
> {
  render() {
    return (
      <div>
        <span style={{ fontSize: "0.8rem" }}>{"Change Over Time"}</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "80px",
            backgroundColor: _.isEmpty(this.props.statusesOverTime) ? "#7b7b7b" : "#464646",
            borderRadius: "5px"
          }}
        >
          {this.props.statusesOverTime.map((statusSlice, index) =>
            this.renderStatusSlice(statusSlice, index)
          )}
        </div>
      </div>
    );
  }

  private renderStatusSlice = (
    statusSlice: HealthStatusSlice,
    index: number
  ): JSX.Element => {
    return (
      <div
        key={`status-slice-${index}`}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          borderRadius: "5px"
        }}
      >
        <div
          style={{
            display: "flex",
            borderRadius: "5px",
            flex: (statusSlice.Healthy / Parameters.POPULATION_SIZE) * 100,
            backgroundColor: generateColor(HealthStatus.Healthy)
          }}
        />
        <div
          style={{
            display: "flex",
            borderRadius: "5px",
            flex: (statusSlice.Infected / Parameters.POPULATION_SIZE) * 100,
            backgroundColor: generateColor(HealthStatus.Infected)
          }}
        />
        <div
          style={{
            display: "flex",
            borderRadius: "5px",
            flex: (statusSlice.Recovered / Parameters.POPULATION_SIZE) * 100,
            backgroundColor: generateColor(HealthStatus.Recovered)
          }}
        />
        <div
          style={{
            display: "flex",
            borderRadius: "5px",
            flex: (statusSlice.Dead / Parameters.POPULATION_SIZE) * 100,
            backgroundColor: generateColor(HealthStatus.Dead)
          }}
        />
        <div
          style={{
            display: "flex",
            borderRadius: "5px",
            flex: (statusSlice.Tester / Parameters.POPULATION_SIZE) * 100,
            backgroundColor: generateColor(HealthStatus.Tester)
          }}
        />
      </div>
    );
  };
}
