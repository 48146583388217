export const defaultParameters = {
    MAX_DOT_SPEED: 1.5,
    MIN_DOT_SPEED: 0.1,
    POPULATION_SIZE: 100,
    INITIAL_NUM_INFECTED: 1,
    INITIAL_NUM_TESTER: 0,
    DOT_SIZE: 5,
    INCUBATION_TIME: 7000,
    LETHALITY: 0.01,
    DEFAULT_QUARANTINE_RADIUS: 200,
    TESTER_SPEED_MULTIPLIER: 2,
    IMMUNITY: true
};
class Parameters {
    public MAX_WIDTH = window.innerWidth;
    public MAX_HEIGHT = window.innerHeight;
    public MAX_DOT_SPEED = defaultParameters.MAX_DOT_SPEED;
    public MIN_DOT_SPEED = defaultParameters.MIN_DOT_SPEED;
    public POPULATION_SIZE = defaultParameters.POPULATION_SIZE;
    public INITIAL_NUM_INFECTED = defaultParameters.INITIAL_NUM_INFECTED;
    public INITIAL_NUM_TESTER = defaultParameters.INITIAL_NUM_TESTER;
    public DOT_SIZE = defaultParameters.DOT_SIZE;
    public INCUBATION_TIME = defaultParameters.INCUBATION_TIME;
    public LETHALITY = defaultParameters.LETHALITY;
    public DEFAULT_QUARANTINE_RADIUS = defaultParameters.DEFAULT_QUARANTINE_RADIUS;
    public TESTER_SPEED_MULTIPLIER = defaultParameters.TESTER_SPEED_MULTIPLIER;
    public IMMUNITY = defaultParameters.IMMUNITY;

    public saveChanges(params: {
        MAX_DOT_SPEED: number,
        MIN_DOT_SPEED: number,
        POPULATION_SIZE: number,
        INITIAL_NUM_INFECTED: number,
        INITIAL_NUM_TESTER: number,
        DOT_SIZE: number,
        INCUBATION_TIME: number,
        LETHALITY: number,
        DEFAULT_QUARANTINE_RADIUS: number,
        TESTER_SPEED_MULTIPLIER: number,
        IMMUNITY: boolean
    }): void {
        this.MAX_DOT_SPEED = params.MAX_DOT_SPEED;
        this.MIN_DOT_SPEED = params.MIN_DOT_SPEED;
        this.POPULATION_SIZE = params.POPULATION_SIZE;
        this.INITIAL_NUM_INFECTED = params.INITIAL_NUM_INFECTED;
        this.INITIAL_NUM_TESTER = params.INITIAL_NUM_TESTER;
        this.DOT_SIZE = params.DOT_SIZE;
        this.INCUBATION_TIME = params.INCUBATION_TIME;
        this.LETHALITY = params.LETHALITY;
        this.DEFAULT_QUARANTINE_RADIUS = params.DEFAULT_QUARANTINE_RADIUS;
        this.TESTER_SPEED_MULTIPLIER = params.TESTER_SPEED_MULTIPLIER;
        this.IMMUNITY = params.IMMUNITY;
    }
}

export default new Parameters();