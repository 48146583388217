import _ from "lodash";
import { Dot, generateDot } from "./dot";
import { HealthStatusSlice, HealthStatus } from "../util";
import Parameters from "../Parameters";

let frameCount = 0;
const chartRerenderRate = 100;
let noMoreInfected = false;
let ongoingSimulationId: number;
let dots: Dot[] = [];
let healthStatusesOverTime: HealthStatusSlice[] = [];

export const beginSimulation = (
  setStatuses: (statuses: HealthStatusSlice[]) => void,
  setDots: (dots: Dot[]) => void,
  setIsInfectionOver: (isOver: boolean) => void
) => {
  console.log(`beginSimulation start`);
  const primaryCanvas = document.getElementById(
    "primaryCanvas"
  ) as HTMLCanvasElement;
  const primaryContext = primaryCanvas.getContext("2d");

  if (primaryContext) {
    if (ongoingSimulationId) {
      window.cancelAnimationFrame(ongoingSimulationId);
    }
    initializeSimulation(primaryContext, setStatuses, setDots, setIsInfectionOver);
    executeSimulation(primaryContext, setStatuses, setIsInfectionOver);
  }
};

export const initializeSimulation = (
  context: CanvasRenderingContext2D,
  setStatuses: (statuses: HealthStatusSlice[]) => void,
  setDots: (dots: Dot[]) => void,
  setIsInfectionOver: (isOver: boolean) => void
): void => {
  dots = [];
  healthStatusesOverTime = [];
  noMoreInfected = false;
  setIsInfectionOver(false);
  frameCount = 0;
  for (let index = 0; index < Parameters.INITIAL_NUM_INFECTED; index++) {
    const dot = generateDot(
      context,
      `Initial-Infected-${index}`,
      HealthStatus.Healthy
    );
    dot.infect();
    dots.push(dot);
  }
  for (let index = 0; index < Parameters.INITIAL_NUM_TESTER; index++) {
    const dot = generateDot(
      context,
      `Initial-Tester-${index}`,
      HealthStatus.Tester
    );
    dots.push(dot);
  }
  for (
    let index = 0;
    index <
    Parameters.POPULATION_SIZE -
      Parameters.INITIAL_NUM_INFECTED -
      Parameters.INITIAL_NUM_TESTER;
    index++
  ) {
    const dot = generateDot(
      context,
      `Initial-Healthy-${index}`,
      HealthStatus.Healthy
    );
    dots.push(dot);
  }
  setDots(dots);
  setStatuses(healthStatusesOverTime);
  console.log(`initializeSimulation complete`);
};

export const executeSimulation = (
  context: CanvasRenderingContext2D,
  setStatuses: (statuses: HealthStatusSlice[]) => void,
  setIsInfectionOver: (isOver: boolean) => void
): void => {
  frameCount += 1;
  context.clearRect(0, 0, Parameters.MAX_WIDTH, Parameters.MAX_HEIGHT);

  if (
    !noMoreInfected &&
    dots.filter(dot => dot.healthStatus === HealthStatus.Infected).length === 0
  ) {
    console.log("No more infected");
    noMoreInfected = true;
    setIsInfectionOver(true);
  }
  // BEGIN
  dots.forEach(dot => {
    if (dot.healthStatus !== HealthStatus.Dead) {
      dot.move();
      dot.collide(dots);
    }
    dot.draw();
  });
  // END
  if (frameCount % chartRerenderRate === 0 || noMoreInfected) {
    healthStatusesOverTime.push({
      Healthy: dots.filter(dot => dot.healthStatus === HealthStatus.Healthy)
        .length,
      Infected: dots.filter(dot => dot.healthStatus === HealthStatus.Infected)
        .length,
      Recovered: dots.filter(dot => dot.healthStatus === HealthStatus.Recovered)
        .length,
      Dead: dots.filter(dot => dot.healthStatus === HealthStatus.Dead).length,
      Tester: dots.filter(dot => dot.healthStatus === HealthStatus.Tester)
        .length
    });
    setStatuses(_.cloneDeep(healthStatusesOverTime));
  }

  if (noMoreInfected) {
    return;
  }
  ongoingSimulationId = window.requestAnimationFrame(() =>
    executeSimulation(context, setStatuses, setIsInfectionOver)
  );
};
