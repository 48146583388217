import React from "react";
import "./App.css";

interface IInputNumberClickerProps {
  label: string;
  value: number;
  tooltip: string;
  onChange: (e: number) => void;
  incrementAmount: number;
  decimalPlaces: number;
}

export default class InputNumberClicker extends React.Component<
  IInputNumberClickerProps,
  {}
> {
  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            style={{
              padding: "5px",
              display: "flex",
              flex: 1,
              maxWidth: "3rem",
              backgroundColor: "#7B7B7B",
              color: "white",
              fontWeight: 900
            }}
            value={this.props.value.toFixed(this.props.decimalPlaces)}
            title={`${this.props.label} - ${this.props.tooltip}`}
            type={"number"}
            onChange={this.handleChange}
          />
          <button
            className={"input-number-clicker-button"}
            style={{
              padding: "0 1rem"
            }}
            onClick={this.onPlusClick}
          >
            +
          </button>
          <button
            className={"input-number-clicker-button"}
            style={{
              padding: "0 1rem",
              borderLeft: "1px solid #7B7B7B"
            }}
            onClick={this.onMinusClick}
          >
            -
          </button>
      </div>
    );
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onChange(Number(e.target.value));
  };

  private onPlusClick = (): void => {
    this.props.onChange(this.props.value + this.props.incrementAmount);
  };

  private onMinusClick = (): void => {
    this.props.onChange(this.props.value - this.props.incrementAmount);
  };
}
