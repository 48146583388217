import React from "react";
import "./App.css";
import { HealthStatusSlice } from "./util";
import InputParams from "./InputParams";
import { Dot } from "./canvas/dot";
import Parameters from "./Parameters";
import InfectionOverNotification from "./InfectionOverNotification";
import Statistics from "./Statistics";
import { Footer } from "./Footer";

interface IAppState {
  dots: Dot[];
  healthStatusesOverTime: HealthStatusSlice[];
  isParamsExpanded: boolean;
  isStatisticsExpanded: boolean;
  isInfectionOver: boolean;
  isStartButtonAnimating: boolean;
}

class App extends React.Component<{}, IAppState> {
  private inputParamsRef: InputParams | null;
  constructor(props: {}) {
    super(props);
    this.inputParamsRef = null;
    this.state = {
      dots: [],
      isStartButtonAnimating: true,
      isParamsExpanded: true,
      isStatisticsExpanded: true,
      isInfectionOver: false,
      healthStatusesOverTime: [],
    };
  }

  componentDidUpdate(prevProps: {}, prevState: IAppState, context: any) {
    if (!prevState.isInfectionOver && this.state.isInfectionOver) {
      this.setIsParamsExpanded(true);
      this.setIsStatisticsExpanded(true);
    }
  }

  render() {
    return (
      <div className={"app-container"}>
        <canvas
          id="primaryCanvas"
          width={Parameters.MAX_WIDTH}
          height={Parameters.MAX_HEIGHT}
        />
        <div
          style={{
            position: "absolute",
            bottom: 5,
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          <Footer />
        </div>
        <div
          style={{
            position: "absolute",
            top: 5,
            left: 5,
            maxWidth: "45%",
          }}
        >
          <Statistics
            healthStatuses={this.state.healthStatusesOverTime}
            dots={this.state.dots}
            isExpanded={this.state.isStatisticsExpanded}
            setIsStatisticsExpanded={this.setIsStatisticsExpanded}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            maxWidth: "45%",
          }}
        >
          <InputParams
            ref={(el) => (this.inputParamsRef = el)}
            setHealthStatusesOverTime={this.setHealthStatusesOverTime}
            setDots={this.setDots}
            isStartButtonAnimating={this.state.isStartButtonAnimating}
            isExpanded={this.state.isParamsExpanded}
            setIsParamsExpanded={this.setIsParamsExpanded}
            setIsStatisticsExpanded={this.setIsStatisticsExpanded}
            setIsInfectionOver={this.setIsInfectionOver}
          />
        </div>
        <InfectionOverNotification isOver={this.state.isInfectionOver} />
      </div>
    );
  }

  private setHealthStatusesOverTime = (
    healthStatusesOverTime: HealthStatusSlice[]
  ): void => {
    this.setState({ healthStatusesOverTime });
  };

  private setDots = (dots: Dot[]): void => {
    this.setState({ dots });
  };

  private setIsParamsExpanded = (isExpanded: boolean): void => {
    this.setState({ isParamsExpanded: isExpanded });
  };

  private setIsStatisticsExpanded = (isExpanded: boolean): void => {
    this.setState({ isStatisticsExpanded: isExpanded });
  };

  private setIsInfectionOver = (over: boolean): void => {
    this.setState({
      isInfectionOver: over,
      isStartButtonAnimating: over ? true : false,
    });
  };
}

export default App;
