import React from "react";
import "./App.css";
import { HealthStatusSlice } from "./util";
import Parameters, { defaultParameters } from "./Parameters";
import { beginSimulation } from "./canvas/simulation";
import { Dot } from "./canvas/dot";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import InputParamBox from "./InputParamBox";
import PresetOptionsContainer from "./PresetOptionsContainer";

interface IInputParamsProps {
  setHealthStatusesOverTime: (statuses: HealthStatusSlice[]) => void;
  setDots: (dots: Dot[]) => void;
  isExpanded: boolean;
  isStartButtonAnimating: boolean;
  setIsParamsExpanded: (isExpanded: boolean) => void;
  setIsStatisticsExpanded: (isExpanded: boolean) => void;
  setIsInfectionOver: (isOver: boolean) => void;
}

interface IInputParamsState {
  MAX_DOT_SPEED: number;
  MIN_DOT_SPEED: number;
  POPULATION_SIZE: number;
  INITIAL_NUM_INFECTED: number;
  INITIAL_NUM_TESTER: number;
  DOT_SIZE: number;
  INCUBATION_TIME: number;
  LETHALITY: number;
  DEFAULT_QUARANTINE_RADIUS: number;
  TESTER_SPEED_MULTIPLIER: number;
  IMMUNITY: boolean;
}

enum PresetOption {
  SpringBreak = "SpringBreak",
  LockDown = "LockDown",
  MassTesting = "MassTesting",
  Apocalypse = "Apocalypse",
  Superman = "Superman"
}

const presetOptions: { [title: string]: Partial<IInputParamsState> } = {
  [PresetOption.SpringBreak]: {
    DEFAULT_QUARANTINE_RADIUS: 5000,
    POPULATION_SIZE: 500,
    INITIAL_NUM_INFECTED: 2,
    DOT_SIZE: 3,
    MAX_DOT_SPEED: 2
  },
  [PresetOption.LockDown]: {
    DEFAULT_QUARANTINE_RADIUS: 25,
    INITIAL_NUM_INFECTED: 2
  },
  [PresetOption.Apocalypse]: {
    DEFAULT_QUARANTINE_RADIUS: 5000,
    INITIAL_NUM_INFECTED: 5,
    MAX_DOT_SPEED: 3,
    INITIAL_NUM_TESTER: 0,
    INCUBATION_TIME: 3000,
    LETHALITY: 2
  },
  [PresetOption.MassTesting]: {
    INITIAL_NUM_INFECTED: 3,
    INITIAL_NUM_TESTER: 20,
    TESTER_SPEED_MULTIPLIER: 15,
    MAX_DOT_SPEED: 1,
    INCUBATION_TIME: 20000
  },
  [PresetOption.Superman]: {
    POPULATION_SIZE: 20,
    INITIAL_NUM_TESTER: 1,
    INITIAL_NUM_INFECTED: 18,
    MAX_DOT_SPEED: 1.2,
    TESTER_SPEED_MULTIPLIER: 30,
    INCUBATION_TIME: 15000,
    DOT_SIZE: 10
  }
};

export default class InputParams extends React.Component<
  IInputParamsProps,
  IInputParamsState
> {
  constructor(props: IInputParamsProps) {
    super(props);
    this.state = {
      MAX_DOT_SPEED: Parameters.MAX_DOT_SPEED,
      MIN_DOT_SPEED: Parameters.MIN_DOT_SPEED,
      POPULATION_SIZE: Parameters.POPULATION_SIZE,
      INITIAL_NUM_INFECTED: Parameters.INITIAL_NUM_INFECTED,
      INITIAL_NUM_TESTER: Parameters.INITIAL_NUM_TESTER,
      DOT_SIZE: Parameters.DOT_SIZE,
      INCUBATION_TIME: Parameters.INCUBATION_TIME,
      LETHALITY: Parameters.LETHALITY,
      DEFAULT_QUARANTINE_RADIUS: Parameters.DEFAULT_QUARANTINE_RADIUS,
      TESTER_SPEED_MULTIPLIER: Parameters.TESTER_SPEED_MULTIPLIER,
      IMMUNITY: Parameters.IMMUNITY
    };
  }

  render() {
    let startButtonClassNames = "input-number-clicker simulate-start-button";
    if (this.props.isStartButtonAnimating) {
      startButtonClassNames += " animate-flicker";
    }
    return (
      <div>
        <button
          className={startButtonClassNames}
          onClick={this.beginUpdatedSimulation}
        >
          Run Simulation
        </button>
        <ExpansionPanel
          defaultExpanded={true}
          expanded={this.props.isExpanded}
          style={{
            marginTop: 0,
            backgroundColor: "#464646",
            color: "white"
          }}
        >
          <ExpansionPanelSummary
            onClick={() =>
              this.props.setIsParamsExpanded(!this.props.isExpanded)
            }
            style={{ height: "1rem" }}
          >
            <span style={{ fontWeight: 100 }}>{"...more options"}</span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
              maxHeight: "80vh",
              overflowY: "scroll"
            }}
          >
            <InputParamBox
              label={"Population Size"}
              tooltip={"How many dots are in the population"}
              iconSrc={"/population.png"}
              value={this.state.POPULATION_SIZE}
              onChange={(newValue: number) =>
                this.setState({ POPULATION_SIZE: newValue })
              }
              incrementAmount={10}
              decimalPlaces={0}
            />
            <InputParamBox
              label={"Initial Number of Infected"}
              tooltip={"How many dots start out infected"}
              iconSrc={"/virus-infected.png"}
              value={this.state.INITIAL_NUM_INFECTED}
              onChange={(newValue: number) =>
                this.setState({ INITIAL_NUM_INFECTED: newValue })
              }
              incrementAmount={1}
              decimalPlaces={0}
            />
            <InputParamBox
              label={"Social Distancing"}
              tooltip={
                "The smaller this number, the less each dot can move. At zero, all dots are frozen in place"
              }
              iconSrc={"/caution-triangle.png"}
              value={this.state.DEFAULT_QUARANTINE_RADIUS}
              onChange={(newValue: number) =>
                this.setState({ DEFAULT_QUARANTINE_RADIUS: newValue })
              }
              incrementAmount={5}
              decimalPlaces={0}
            />
            <InputParamBox
              label={"Number of Testers"}
              tooltip={
                "How many dots are testers. Testers quarntine infected dots by stopping their movement"
              }
              iconSrc={"/test-tube.png"}
              value={this.state.INITIAL_NUM_TESTER}
              onChange={(newValue: number) =>
                this.setState({ INITIAL_NUM_TESTER: newValue })
              }
              incrementAmount={1}
              decimalPlaces={0}
            />
            <InputParamBox
              label={"Incubation Time"}
              tooltip={
                "How long it takes to either recover or die after being infected"
              }
              iconSrc={"/timer-clock.png"}
              value={this.state.INCUBATION_TIME}
              onChange={(newValue: number) =>
                this.setState({ INCUBATION_TIME: newValue })
              }
              incrementAmount={1000}
              decimalPlaces={0}
            />
            <InputParamBox
              label={"Lethality"}
              tooltip={
                "How likely a dot is to die instead of recover after being infected. This chance is increased for older dots"
              }
              iconSrc={"/skull.png"}
              value={this.state.LETHALITY}
              onChange={(newValue: number) =>
                this.setState({ LETHALITY: newValue })
              }
              incrementAmount={0.01}
              decimalPlaces={3}
            />
            <InputParamBox
              label={"Tester Urgency"}
              tooltip={"How much faster testers move than other dots"}
              iconSrc={"/running.png"}
              value={this.state.TESTER_SPEED_MULTIPLIER}
              onChange={(newValue: number) =>
                this.setState({ TESTER_SPEED_MULTIPLIER: newValue })
              }
              incrementAmount={1}
              decimalPlaces={0}
            />
            <InputParamBox
              label={"Dot Speed"}
              tooltip={"Alters the maximum speed any dot can move"}
              iconSrc={"/up-arrow.png"}
              value={this.state.MAX_DOT_SPEED}
              onChange={(newValue: number) =>
                this.setState({ MAX_DOT_SPEED: newValue })
              }
              incrementAmount={0.1}
              decimalPlaces={1}
            />
            {/* <InputParamBox
              label={"Min Dot Speed"}
              tooltip={"Alters the minimum speed any dot can move"}
              iconSrc={"/down-arrow.png"}
              value={this.state.MIN_DOT_SPEED}
              onChange={(newValue: number) =>
                this.setState({ MIN_DOT_SPEED: newValue })
              }
              incrementAmount={0.1}
              decimalPlaces={1}
            /> */}
            <InputParamBox
              label={"Dot Size"}
              tooltip={"Alters the size of each dot"}
              iconSrc={"/circle-in-square.png"}
              value={this.state.DOT_SIZE}
              onChange={(newValue: number) =>
                this.setState({ DOT_SIZE: newValue })
              }
              incrementAmount={1}
              decimalPlaces={0}
            />
            <PresetOptionsContainer
              presetOptions={[
                {
                  title: "Lockdown",
                  onClick: () => this.setPresetOption(PresetOption.LockDown)
                },
                {
                  title: "Mass Testing",
                  onClick: () => this.setPresetOption(PresetOption.MassTesting)
                },
                {
                  title: "Spring Break",
                  onClick: () => this.setPresetOption(PresetOption.SpringBreak)
                },
                {
                  title: "Superman to the Rescue",
                  onClick: () => this.setPresetOption(PresetOption.Superman)
                },
                {
                  title: "Apocalypse",
                  onClick: () => this.setPresetOption(PresetOption.Apocalypse)
                }
              ]}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }

  public beginUpdatedSimulation = (): void => {
    Parameters.saveChanges({
      MAX_DOT_SPEED: this.state.MAX_DOT_SPEED,
      MIN_DOT_SPEED: this.state.MIN_DOT_SPEED,
      POPULATION_SIZE: this.state.POPULATION_SIZE,
      INITIAL_NUM_INFECTED: this.state.INITIAL_NUM_INFECTED,
      INITIAL_NUM_TESTER: this.state.INITIAL_NUM_TESTER,
      DOT_SIZE: this.state.DOT_SIZE,
      INCUBATION_TIME: this.state.INCUBATION_TIME,
      LETHALITY: this.state.LETHALITY,
      DEFAULT_QUARANTINE_RADIUS: this.state.DEFAULT_QUARANTINE_RADIUS,
      TESTER_SPEED_MULTIPLIER: this.state.TESTER_SPEED_MULTIPLIER,
      IMMUNITY: this.state.IMMUNITY
    });
    this.props.setIsParamsExpanded(false);
    this.props.setIsStatisticsExpanded(false);
    beginSimulation(
      this.props.setHealthStatusesOverTime,
      this.props.setDots,
      this.props.setIsInfectionOver
    );
  };

  private setPresetOption = (presetOption: PresetOption): void => {
    this.resetParams(() => {
      const presetParams = presetOptions[presetOption];
      const newParams = { ...this.state, ...presetParams };
      this.setState({ ...newParams }, this.beginUpdatedSimulation);
    });
  };

  private resetParams = (callback: () => void): void => {
    this.setState(
      {
        MAX_DOT_SPEED: defaultParameters.MAX_DOT_SPEED,
        MIN_DOT_SPEED: defaultParameters.MIN_DOT_SPEED,
        POPULATION_SIZE: defaultParameters.POPULATION_SIZE,
        INITIAL_NUM_INFECTED: defaultParameters.INITIAL_NUM_INFECTED,
        INITIAL_NUM_TESTER: defaultParameters.INITIAL_NUM_TESTER,
        DOT_SIZE: defaultParameters.DOT_SIZE,
        INCUBATION_TIME: defaultParameters.INCUBATION_TIME,
        LETHALITY: defaultParameters.LETHALITY,
        DEFAULT_QUARANTINE_RADIUS: defaultParameters.DEFAULT_QUARANTINE_RADIUS,
        TESTER_SPEED_MULTIPLIER: defaultParameters.TESTER_SPEED_MULTIPLIER,
        IMMUNITY: defaultParameters.IMMUNITY
      },
      callback
    );
  };
}
