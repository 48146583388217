import React from "react";
import "./App.css";
import { Snackbar } from "@material-ui/core";

interface IInfectionOverNotificationProps {
  isOver: boolean;
}

const InfectionOverNotification: React.FunctionComponent<IInfectionOverNotificationProps> = props => {
  return (
    <Snackbar open={props.isOver}>
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom right, #63EC2F, #464646, #464646, #F12804)`,
          color: "white",
          padding: "1rem",
          borderRadius: "5px",
          fontWeight: "bold"
        }}
      >
        <span>{"Infection Over"}</span>
      </div>
    </Snackbar>
  );
};

export default InfectionOverNotification;
