import React from "react";
import "./App.css";
import {
  generateColor,
  generateSecondaryColor,
  HealthStatus
} from "./util";
import { Tooltip } from "@material-ui/core";

const renderLegendKey = (
  primaryColor: string,
  secondaryColor: string,
  label: string,
  tooltip: string
): JSX.Element => {
  return (
    <Tooltip title={tooltip}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "0.2rem 0",
          alignItems: "center"
        }}
      >
        <div
          style={{
            width: "0.6rem",
            height: "0.6rem",
            borderRadius: "1rem",
            backgroundColor: primaryColor,
            border: `3px solid ${secondaryColor}`,
            marginRight: "0.4rem"
          }}
        />
        <span style={{ fontSize: "0.9rem" }}>{label}</span>
      </div>
    </Tooltip>
  );
};

const Legend: React.FunctionComponent = () => {
  return (
    <div className={"statistics-section"}>
      {renderLegendKey(
        generateColor(HealthStatus.Healthy),
        generateSecondaryColor(HealthStatus.Healthy, false),
        "Healthy",
        "Healthy dots can become infected"
      )}
      {renderLegendKey(
        generateColor(HealthStatus.Infected),
        generateSecondaryColor(HealthStatus.Infected, false),
        "Infected",
        "Infected dots pass it on to other healthy dots"
      )}
      {renderLegendKey(
        generateColor(HealthStatus.Recovered),
        generateSecondaryColor(HealthStatus.Recovered, false),
        "Recovered",
        "Infected dots that have recovered"
      )}
      {renderLegendKey(
        generateColor(HealthStatus.Dead),
        generateSecondaryColor(HealthStatus.Dead, false),
        "Dead",
        "Infected dots that didn't make it"
      )}
      {renderLegendKey(
        generateColor(HealthStatus.Tester),
        generateSecondaryColor(HealthStatus.Tester, false),
        "Tester",
        "These dots quarantine infected by freezing them in place"
      )}
      {renderLegendKey(
        "transparent",
        generateSecondaryColor(HealthStatus.Infected, true),
        "Quarantined",
        "Quarantined dots cannot move"
      )}
    </div>
  );
};

export default Legend;
