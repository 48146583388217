import React from "react";
import "./App.css";
import { HealthStatusSlice } from "./util";
import { Dot } from "./canvas/dot";
import Legend from "./Legend";
import StatusOverTime from "./StatusOverTime";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import About from "./About";

interface IStatisticsProps {
  healthStatuses: HealthStatusSlice[];
  dots: Dot[];
  isExpanded: boolean;
  setIsStatisticsExpanded: (isExpanded: boolean) => void;
}

const Statistics: React.FunctionComponent<IStatisticsProps> = props => {
  return (
    <div>
      <ExpansionPanel
        defaultExpanded={true}
        expanded={props.isExpanded}
        style={{
          marginTop: 0,
          backgroundColor: "#464646",
          color: "white"
        }}
      >
        <ExpansionPanelSummary
          onClick={() => props.setIsStatisticsExpanded(!props.isExpanded)}
        >
          <span style={{ fontWeight: "bold" }}>{"Details"}</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: "1px solid black",
            maxHeight: "80vh",
            overflowY: "scroll"
          }}
        >
          <Legend />
          {/* <AgeGroupStatistics
            healthStatuses={props.healthStatuses}
            dots={props.dots}
          /> */}
          <StatusOverTime statusesOverTime={props.healthStatuses} />
          <About
            title={"About"}
            texts={[
              "This tool is to help teach people how infections can spread, and what conditions affect them. We've given you control over the conditions so you can experiment for yourself and see the effects."
            ]}
            links={[]}
          />
          <About
            title={"Acknowledgements"}
            texts={[
              "Powered by SimplyScreen",
              "Inspired by this Washington Post article",
              "All icons provided by icons8.com"
            ]}
            links={["https://simplyscreen.care/", "https://www.washingtonpost.com/graphics/2020/world/corona-simulator/","https://icons8.com"]}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default Statistics;
