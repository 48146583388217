import React from "react";
import "./App.css";

interface IAboutProps {
  title: string;
  texts: string[];
  links: string[];
}

const About: React.FunctionComponent<IAboutProps> = props => {
  return (
    <div
      style={{
        color: "white",
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        maxWidth: "10rem"
      }}
    >
      <span style={{ fontSize: "0.8rem" }}>{props.title}</span>
      {props.texts.map((text, index) => {
        const link = props.links[index];
        return !!link ? (
          <a
            style={{ marginBottom: "0.5rem" }}
            key={`about-link-${text}-${index}`}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {renderText(text, true)}
          </a>
        ) : (
          renderText(text, false)
        );
      })}
    </div>
  );
};

const renderText = (text: string, isLink: boolean): JSX.Element => {
  return (
    <span
      key={`about-text-${text}`}
      style={{
        color: isLink ? "#6b87ce" : "rgb(158, 158, 158)",
        fontSize: "0.9rem",
        fontWeight: 100,
        marginBottom: "0.5rem"
      }}
    >
      {text}
    </span>
  );
};

export default About;
