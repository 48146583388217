import _ from "lodash";
import Parameters from "./Parameters";

export interface HealthStatusSlice {
  Healthy: number;
  Infected: number;
  Recovered: number;
  Dead: number;
  Tester: number;
}

export enum HealthStatus {
  Healthy = "Healthy",
  Infected = "Infected",
  Recovered = "Recovered",
  Dead = "Dead",
  Tester = "Tester"
}

export enum AgeGroup {
  Child = "Child",
  YoungAdult = "YoungAdult",
  MiddleAged = "MiddleAged",
  Elderly = "Elderly"
}

export enum Wall {
  N = "North",
  E = "East",
  W = "West",
  S = "South"
}

export const convertAgeToGroup = (age: number): AgeGroup => {
  if (age < 9) {
    return AgeGroup.Child;
  } else if (age < 21) {
    return AgeGroup.YoungAdult;
  } else if (age < 60) {
    return AgeGroup.MiddleAged;
  } else {
    return AgeGroup.Elderly;
  }
};

export const generateColor = (healthStatus: HealthStatus): string => {
  switch (healthStatus) {
    case HealthStatus.Healthy:
      return "#63EC2F";
    case HealthStatus.Infected:
      return "#F12804";
    case HealthStatus.Recovered:
      return "#38E0EE";
    case HealthStatus.Dead:
      return "black";
    case HealthStatus.Tester:
      return "#D26CF6";
  }
};

export const generateSecondaryColor = (
  healthStatus: HealthStatus,
  isQuarantined: boolean
): string => {
  if (isQuarantined) {
    return generateColor(HealthStatus.Tester);
  }
  switch (healthStatus) {
    case HealthStatus.Healthy:
      return "#4FB129";
    case HealthStatus.Infected:
      return "#A4220B";
    case HealthStatus.Recovered:
      return "#1997A1";
    case HealthStatus.Dead:
      return "black";
    case HealthStatus.Tester:
      return "#73059C";
  }
};

export const generateRandomVelocity = (healthStatus: HealthStatus): number => {
  const negativeNumber = _.random(
    -generateMaxDotSpeed(healthStatus),
    -Parameters.MIN_DOT_SPEED,
    true
  );
  const positiveNumber = _.random(
    Parameters.MIN_DOT_SPEED,
    generateMaxDotSpeed(healthStatus),
    true
  );
  return fiftyPercentChance() ? negativeNumber : positiveNumber;
};

export const generateMaxDotSpeed = (healthStatus: HealthStatus): number => {
  return (
    Parameters.MAX_DOT_SPEED *
    (healthStatus === HealthStatus.Tester
      ? Parameters.MAX_DOT_SPEED * Parameters.TESTER_SPEED_MULTIPLIER
      : Parameters.MAX_DOT_SPEED)
  );
};

export const generateSize = (healthStatus: HealthStatus): number => {
  let size = Parameters.DOT_SIZE;
  return size;
};

export const calculatorDistance = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number => {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
};

const fiftyPercentChance = (): boolean => Math.random() > 0.5;
