import React from "react";
import "./App.css";

interface IPresetOptionsContainerProps {
  presetOptions: {
    title: string;
    onClick: () => void;
  }[];
}

const PresetOptionsContainer: React.FunctionComponent<IPresetOptionsContainerProps> = props => {
  return (
    <div>
      <div style={{ margin: "0.5rem 0 0.5rem 0.5rem" }}>
        <span>{"Preset Scenarios"}</span>
      </div>
      {props.presetOptions.map(preset => {
        return (
          <div
            key={`preset-button-${preset.title}`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <button
                className={"preset-option-button"}
              onClick={preset.onClick}
              style={{
                padding: "5px",
                width: "100%",
                fontSize: "0.8rem"
              }}
            >
              <span>{preset.title}</span>
            </button>
            <div
              style={{
                borderTop: "1px solid #7B7B7B",
                height: "1px",
                width: "30%",
                margin: "0.5rem 0 0.5rem 0",
                alignSelf: "center"
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PresetOptionsContainer;
