import React from "react";
import "./App.css";
import InputNumberClicker from "./InputNumberClicker";
import { Tooltip } from "@material-ui/core";

interface IInputParamBoxProps {
  label: string;
  tooltip: string;
  iconSrc: string;
  value: number;
  onChange: (e: number) => void;
  incrementAmount: number;
  decimalPlaces: number;
}

interface IInputParamBoxState {
  isTooltipOpen: boolean;
}

export default class InputParamBox extends React.Component<
  IInputParamBoxProps,
  IInputParamBoxState
> {
  constructor(props: IInputParamBoxProps) {
    super(props);
    this.state = {
      isTooltipOpen: false
    };
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.5rem 0"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            paddingLeft: "0.3rem",
            justifyContent: "space-between"
          }}
          onMouseOver={this.openTooltip}
          onMouseLeave={this.closeTooltip}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <Tooltip title={this.props.tooltip} open={this.state.isTooltipOpen}>
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }}
                src={this.props.iconSrc}
                alt={`input param box icon ${this.props.tooltip}`}
              />
            </Tooltip>
            <span style={{ fontSize: "0.8rem", color: "white" }}>
              {this.props.label}
            </span>
          </div>

          {/* <img
              style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }}
              src={"/question-mark.png"}
            /> */}
        </div>
        <div
          style={{
            marginTop: "0.3rem",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <InputNumberClicker {...this.props} />
        </div>
        <div
          style={{
            borderTop: "1px solid #7B7B7B",
            height: "1px",
            width: "50%",
            marginTop: "0.5rem",
            alignSelf: "center"
          }}
        />
      </div>
    );
  }

  private openTooltip = (): void => {
    this.setState({ isTooltipOpen: true });
  };

  private closeTooltip = (): void => {
    this.setState({ isTooltipOpen: false });
  };
}
