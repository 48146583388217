import React from "react";

export const Footer: React.FunctionComponent<{}> = (props) => {
  return (
    <a
      href={"https://simplyscreen.care/"}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: "gray"
      }}
    >
      <span>{"Powered by SimplyScreen"}</span>
      <img
        src={"/simply-screen-logo.png"}
        style={{ height: "2rem", marginLeft: "0.3rem" }}
        alt={"SimplyScreen logo"}
      />
    </a>
  );
};
