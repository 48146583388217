import {
  calculatorDistance,
  HealthStatus,
  generateColor,
  generateSecondaryColor,
  generateMaxDotSpeed,
  Wall,
  convertAgeToGroup,
  AgeGroup,
  generateRandomVelocity,
  generateSize
} from "../util";
import _ from "lodash";
import Parameters from "../Parameters";

export class Dot {
  private context: CanvasRenderingContext2D;
  private id: string;
  private previousCoords: { x: number; y: number }[];
  x: number;
  y: number;
  private quarantineX: number;
  private quarantineY: number;
  private velocityX: number;
  private velocityY: number;
  private speed: number;
  private hasRecovered: boolean = false;
  private isQuarantined: boolean = false;
  private quarantineRadius: number = Parameters.DEFAULT_QUARANTINE_RADIUS;
  private size: number;
  healthStatus: HealthStatus;
  age: number;
  constructor(
    context: CanvasRenderingContext2D,
    id: string,
    quarantineX: number,
    quarantineY: number,
    initialVelocityX: number,
    initialVelocityY: number,
    initialHealthStatus: HealthStatus,
    age: number,
    size: number
  ) {
    this.context = context;
    this.id = id;
    this.x = quarantineX;
    this.y = quarantineY;
    this.quarantineX = quarantineX;
    this.quarantineY = quarantineY;
    this.velocityX = initialVelocityX;
    this.velocityY = initialVelocityY;
    this.speed = 1;
    this.healthStatus = initialHealthStatus;
    this.age = age;
    this.size = size;
    this.previousCoords = [];

    if (initialHealthStatus === HealthStatus.Infected) {
      this.infect();
    }
  }

  coords = (): { x: number; y: number } => {
    return {
      x: this.x,
      y: this.y
    };
  };

  move = (): void => {
    if (!this.isQuarantined) {
      this.x += this.velocityX * this.speed;
      this.y += this.velocityY * this.speed;
    }
  };

  draw = (): void => {
    this.context.fillStyle = generateColor(this.healthStatus);
    this.context.beginPath();
    this.context.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
    this.context.lineWidth = generateSize(this.healthStatus) * 1.5;
    this.context.strokeStyle = generateSecondaryColor(
      this.healthStatus,
      this.isQuarantined
    );
    this.context.stroke();
    this.context.fill();
    this.drawTrail();
  };

  infect = (): void => {
    if (this.ignoreInfection()) {
      return;
    }

    this.healthStatus = HealthStatus.Infected;
    this.hasRecovered = false;
    setTimeout(() => {
      if (this.calculateIsDead()) {
        this.healthStatus = HealthStatus.Dead;
      } else {
        this.hasRecovered = true;
        this.healthStatus = HealthStatus.Recovered;
      }
    }, Parameters.INCUBATION_TIME);
  };

  collide = (otherDots: Dot[]): void => {
    otherDots.forEach(otherDot => {
      if (
        otherDot.id !== this.id &&
        otherDot.healthStatus !== HealthStatus.Dead
      ) {
        if (this.checkForDotCollision(otherDot)) {
          this.changeDirectionFromDot(otherDot.x, otherDot.y);
          otherDot.changeDirectionFromDot(this.x, this.y);

          if (this.healthStatus === HealthStatus.Infected) {
            otherDot.infect();
          }

          if (otherDot.healthStatus === HealthStatus.Infected) {
            this.infect();
          }

          if (
            this.healthStatus === HealthStatus.Infected &&
            otherDot.healthStatus === HealthStatus.Tester
          ) {
            this.quarantine();
          }

          if (
            otherDot.healthStatus === HealthStatus.Infected &&
            this.healthStatus === HealthStatus.Tester
          ) {
            otherDot.quarantine();
          }
        }
      }
    });

    const wallCollision = this.checkForWallCollision();
    const quarantineColision = this.checkForQuarantineCollision();
    if (wallCollision) {
      this.changeDirectionFromWall(wallCollision);
    }
    if (quarantineColision) {
      this.changeDirectionFromWall(quarantineColision);
    }
  };

  quarantine = (): void => {
    this.quarantineX = this.x;
    this.quarantineY = this.y;
    this.isQuarantined = true;
  };

  changeDirectionFromDot = (otherX: number, otherY: number): void => {
    if (
      Math.abs(Math.abs(this.x) - Math.abs(otherX)) >
      Math.abs(Math.abs(this.y) - Math.abs(otherY))
    ) {
      this.velocityX = this.velocityX * -1;
    } else {
      this.velocityY = this.velocityY * -1;
    }
  };

  changeDirectionFromWall = (wall: Wall): void => {
    switch (wall) {
      case Wall.N:
        this.y = this.y - generateMaxDotSpeed(this.healthStatus);
        this.velocityY = this.velocityY * -1;
        break;
      case Wall.S:
        this.y = this.y + generateMaxDotSpeed(this.healthStatus);
        this.velocityY = this.velocityY * -1;
        break;
      case Wall.E:
        this.x = this.x - generateMaxDotSpeed(this.healthStatus);
        this.velocityX = this.velocityX * -1;
        break;
      case Wall.W:
        this.x = this.x + generateMaxDotSpeed(this.healthStatus);
        this.velocityX = this.velocityX * -1;
        break;
    }
  };

  private checkForDotCollision = (otherDot: Dot): boolean => {
    const distance = calculatorDistance(this.x, this.y, otherDot.x, otherDot.y);
    return distance < this.size * 2;
  };

  private checkForWallCollision = (): Wall | undefined => {
    if (Math.abs(this.x) <= 0 + generateMaxDotSpeed(this.healthStatus)) {
      return Wall.W;
    }
    if (Math.abs(this.y) <= 0 + generateMaxDotSpeed(this.healthStatus)) {
      return Wall.S;
    }
    if (
      Math.abs(this.x) >=
      Parameters.MAX_WIDTH - generateMaxDotSpeed(this.healthStatus)
    ) {
      return Wall.E;
    }
    if (
      Math.abs(this.y) >=
      Parameters.MAX_HEIGHT - generateMaxDotSpeed(this.healthStatus)
    ) {
      return Wall.N;
    }
  };

  private checkForQuarantineCollision = (): Wall | undefined => {
    if (this.healthStatus === HealthStatus.Tester) {
      return;
    }
    if (
      this.quarantineX - (this.x + generateMaxDotSpeed(this.healthStatus)) >=
      this.quarantineRadius
    ) {
      return Wall.W;
    }
    if (
      this.quarantineY - (this.y + generateMaxDotSpeed(this.healthStatus)) >=
      this.quarantineRadius
    ) {
      return Wall.S;
    }
    if (
      this.quarantineX - (this.x + generateMaxDotSpeed(this.healthStatus)) <=
      -this.quarantineRadius
    ) {
      return Wall.E;
    }
    if (
      this.quarantineY - (this.y + generateMaxDotSpeed(this.healthStatus)) <=
      -this.quarantineRadius
    ) {
      return Wall.N;
    }
  };

  private calculateIsDead = (): boolean => {
    const ageGroup = convertAgeToGroup(this.age);
    switch (ageGroup) {
      case AgeGroup.Child:
        return Math.random() < Parameters.LETHALITY * 0.5;
      case AgeGroup.YoungAdult:
        return Math.random() < Parameters.LETHALITY * 1;
      case AgeGroup.MiddleAged:
        return Math.random() < Parameters.LETHALITY * 2;
      case AgeGroup.Elderly:
        return Math.random() < Parameters.LETHALITY * 6;
    }
  };

  private ignoreInfection = (): boolean => {
    return (
      this.healthStatus === HealthStatus.Infected ||
      this.healthStatus === HealthStatus.Dead ||
      this.healthStatus === HealthStatus.Tester ||
      (this.healthStatus === HealthStatus.Recovered &&
        Parameters.IMMUNITY &&
        this.hasRecovered)
    );
  };

  private drawTrail = (): void => {
    if (this.healthStatus === HealthStatus.Tester) {
      this.previousCoords.forEach((coords, index) => {
        const sizeDivider = index || 1;
        this.context.fillStyle = generateColor(this.healthStatus);
        this.context.beginPath();
        this.context.arc(
          coords.x,
          coords.y,
          this.size / sizeDivider,
          0,
          2 * Math.PI
        );
        this.context.lineWidth = generateSize(this.healthStatus) * 0.6;
        this.context.strokeStyle = "transparent";
        this.context.stroke();
        this.context.fill();
      });
      if (this.previousCoords.length > 3) {
        this.previousCoords.pop();
      }
      this.previousCoords.unshift({ x: this.x, y: this.y });
    }
  };
}

export const generateDot = (
  context: CanvasRenderingContext2D,
  id: string,
  healthStatus: HealthStatus
): Dot => {
  return new Dot(
    context,
    id,
    _.random(0, Parameters.MAX_WIDTH),
    _.random(0, Parameters.MAX_HEIGHT),
    generateRandomVelocity(healthStatus),
    generateRandomVelocity(healthStatus),
    healthStatus,
    _.random(1, 75),
    generateSize(healthStatus)
  );
};
